import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const BoxtUp = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: 200,
                        objectFit: 'contain'
                    }}
                    src={require('../assets/boxtup-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '2%'}}>
                <p style={{textAlign: 'center'}}>Storage Solution with QR Codes</p>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>BoxtUp is the ultimate app for organizing your belongings and keeping track of what's inside your boxes. Say goodbye to the hassle of searching through stacks of boxes when you need something specific. With BoxtUp, you can easily create an inventory of your items, add photos, and generate QR codes to make finding and managing your possessions a breeze.</p>
                <div>
                    <p>Key Features:</p>
                    <ul>
                        <li>Effortless Box Indexing: Quickly create a digital inventory of your boxes and their contents. Say goodbye to manual lists and messy handwriting.</li>
                        <br/>
                        <li>Visualize with Photos: Snap photos of your items and attach them to each box for a visual reference. Easily recognize what's inside without even opening the box.</li>
                        <br/>
                        <li>Generate QR Codes: BoxtUp generates unique QR codes for each box you create, making identification and retrieval a breeze. Stick the code on the box, and you're all set!</li>
                        <br/>
                        <li>Scan and Discover: Use your device's camera to scan the QR codes on your boxes. BoxtUp will instantly reveal the contents of the box, making it a breeze to find what you're looking for.</li>
                        <br/>
                        <li>Custom Categories & Tags: Organize your boxes by creating custom categories such as "Home," "Office," "Storage," or any other category that suits your needs.</li>
                        <br/>
                        <li>Search and Filter: Effortlessly search for specific items or filter your boxes by category, name, or any other attribute. Locate your belongings in seconds.</li>
                    </ul>
                </div>
                <p>Avoid the stress of forgetting what's stored in each box. Download BoxtUp today and unlock the magic of organized boxes!</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/app/boxtup/id6450389593'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '1%'}}>
                <a href='https://play.google.com/store/apps/details?id=com.herocode.boxtup'>
                    <Image
                    style={{width: '175px'}}
                        src={require('../assets/google-play-badge.png')}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <a href='https://www.herocode.us/boxtup/termsandconditions/'>Terms & Conditions</a>
                <a href='https://www.herocode.us/boxtup/privacypolicy/'>Privacy Policy</a>
            </Row>
        </Container>
    )
}

export default BoxtUp