import React from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import BpTracker from '../components/BpTracker'
import CardPixel from '../components/CardPixel'
import ContactUs from '../components/ContactUs'
import NavigationBar from '../components/NavigationBar'
import OurApps from '../components/OurApps'
import SparkBoost from '../components/SparkBoost'
import ScrollToTop from '../components/ScrollToTop'
import WifiConnector from '../components/WifiConnector'
import BoxtUp from '../components/BoxtUp'
import StudyFlip from '../components/StudyFlip'
import PixelScrollr from '../components/PixelScrollr'
import PixelGreens from '../components/PixelGreens'
import PixelGreensAgreements from '../components/PixelGreensAgreements'
import PixelPinsAgreements from '../components/PixelPinsAgreements'
import PixelPins from '../components/PixelPins'
import SpeedRollr from '../components/SpeedRollr'
import SpeedRollrAgreements from '../components/SpeedRollrAgreements'
import ObstacleOutrun from '../components/ObstacleOutrun'
import ObstacleOutrunAgreements from '../components/ObstacleOutrunAgreements'

const ApplicationRouter = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <NavigationBar />
            <div style={{paddingBottom: 50, paddingTop: 5}}>
                <Routes>
                    <Route path='/' element={<OurApps />} />
                    <Route path='/apps' element={<OurApps />} />
                    <Route path='/bp-tracker' element={<BpTracker />} />
                    <Route path='/boxtup' element={<BoxtUp />} />
                    <Route path='/cardpixel' element={<CardPixel />} />
                    <Route path='/contact' element={<ContactUs />} />
                    <Route path='/obstacleoutrun' element={<ObstacleOutrun />} />
                    <Route path='/obstacleoutrun/agreements' element={<ObstacleOutrunAgreements />} />
                    <Route path='/pixelgreens' element={<PixelGreens />} />
                    <Route path='/pixelgreens/agreements' element={<PixelGreensAgreements />} />
                    <Route path='/pixelpins' element={<PixelPins />} />
                    <Route path='/pixelpins/agreements' element={<PixelPinsAgreements />} />
                    <Route path='/pixelscrollr' element={<PixelScrollr />} />
                    <Route path='/sparkboost' element={<SparkBoost />} />
                    <Route path='/speedrollr' element={<SpeedRollr />} />
                    <Route path='/speedrollr/agreements' element={<SpeedRollrAgreements />} />
                    <Route path='/studyflip' element={<StudyFlip />} />
                    <Route path='/wifi-connector' element={<WifiConnector />} />
                    <Route path='*' element={<OurApps />} />
                </Routes>
            </div>
            <footer style={{position: 'fixed', padding: 5, bottom: 0, width: '100%', height: '35px', background: '#212529', color: '#fff', textAlign: 'center', fontSize: 13}}>Copyright &copy; 2024 Hero Code LLC. All rights reserved.</footer>
        </BrowserRouter>
    )
}

export default ApplicationRouter