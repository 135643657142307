import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const StudyFlip = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: 300,
                        objectFit: 'contain'
                    }}
                    src={require('../assets/studyflip-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '2%'}}>
                <p style={{textAlign: 'center'}}>Ultimate Flashcard Companion</p>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>Unlock the potential of your mind with Study Flip, the innovative flashcard app designed to supercharge your learning experience. Whether you're a student, professional, or lifelong learner, this app is your secret weapon to mastering any subject with ease.</p>
                <div>
                    <p>Key Features:</p>
                    <ul>
                        <li>Create Stunning Flashcards. Unleash your creativity with Study Flip's highly customizable editor. Craft visually appealing flashcards by seamlessly inserting images and adjusting font styles, weights, and sizes. Personalize your cards to suit your learning style and make studying a delightful experience.</li>
                        <br/>
                        <li>Effortless Organization. Stay organized and efficient with Study Flip's intuitive interface. Create decks for different subjects, topics, or courses, and arrange your flashcards in a way that makes sense to you. Effortlessly navigate through your decks and focus on the information that matters most.</li>
                        <br/>
                        <li>Flip and Master. Engage in active learning with a simple tap or swipe. Flip your flashcards to test your knowledge and recall. Challenge yourself to remember the answers and reinforce your learning. Study Flip's scientifically proven method of spaced repetition ensures efficient information retention and long-term memory recall.</li>
                    </ul>
                </div>
                <p>Unlock the power of your mind and embark on a journey of effortless learning with Study Flip. Download now to create visually stunning flashcards, organize them effortlessly, master subjects with active learning, study anytime, anywhere, and track your progress along the way.</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/app/study-flip/id6451048024'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '1%'}}>
                <a href='https://play.google.com/store/apps/details?id=com.herocode.studyflip'>
                    <Image
                    style={{width: '175px'}}
                        src={require('../assets/google-play-badge.png')}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <a href='https://www.herocode.us/studyflip/termsandconditions/'>Terms & Conditions</a>
                <a href='https://www.herocode.us/studyflip/privacypolicy/'>Privacy Policy</a>
            </Row>
        </Container>
    )
}

export default StudyFlip