import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const SparkBoost = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/sparkboost-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '5%'}}>
                <p style={{textAlign: 'center'}}>Spark your day with inspiration, boost your life with Spark Boost!</p>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>Spark Boost is the ultimate motivation app that helps you kick-start your day with inspiring quotes and positive affirmations. Whether you need a boost of confidence, a jolt of energy, or just some words of encouragement, Spark Boost has you covered.</p>
                <p>With a vast collection of carefully curated quotes from famous authors, motivational speakers, and thought leaders, Spark Boost delivers daily inspiration to help you overcome obstacles, achieve your goals, and live your best life.</p>
                <div>
                    <p>Features:</p>
                    <ul>
                        <li>Daily dose of motivation: Get a new quote every day to keep you inspired and motivated.</li>
                        <br/>
                        <li>Custom backgrounds: Choose from a range of beautiful backgrounds to customize your quote experience.</li>
                        <br/>
                        <li>Quote categories: Browse quotes by category, including inspirational, happiness, success, and more.</li>
                        <br/>
                        <li>Save and share: Save your favorite quotes to read later, or share them with friends and family via social media, text, or email.</li>
                        <br/>
                        <li>Offline access: Enjoy Spark Boost even when you're offline or on airplane mode.</li>
                    </ul>
                </div>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/app/spark-boost/id6447004958'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <a href='https://www.herocode.us/sparkboost/termsandconditions/'>Terms & Conditions</a>
                <a href='https://www.herocode.us/sparkboost/privacypolicy/'>Privacy Policy</a>
            </Row>
        </Container>
    )
}

export default SparkBoost