import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const PixelPinsAgreements = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '500px',
                    }}
                    src={require('../assets/pixelpins-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '5%'}}>
                <p style={{textAlign: 'center', fontSize: '2em'}}>Policies & Agreements</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'column', textAlign: 'center', fontSize: '1.3em' }}>
                <a href='https://www.herocode.us/pixelpins/termsandconditions/'>Terms & Conditions</a>
                <a href='https://www.herocode.us/pixelpins/privacypolicy/' style={{ marginTop: 15}}>Privacy Policy</a>
                <a href='https://www.herocode.us/pixelpins/eula/' style={{ marginTop: 15, marginBottom: 15}}>End-User License Agreement</a>
            </Row>
            <Row style={{marginTop: '3%'}}>
                <p style={{fontSize: '0.75em'}}>Pixel Pins Bowling uses Unreal® Engine. Unreal® is a trademark or registered trademark of Epic Games, Inc. in the United States of America and elsewhere. Unreal® Engine, Copyright 1998 – 2024, Epic Games, Inc. All rights reserved.</p>
            </Row>
        </Container>
    )
}

export default PixelPinsAgreements