import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'

const SpeedRollr = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/speedrollr-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center', fontSize: 27}}>Roll, Speed, Conquer!</p>
            </Row>
            <Row style={{justifyContent: 'center', alignItems: 'center', marginTop: 25}}>
                <div style={{width: '90%'}}>
                    <Carousel>
                    <div>
                        <img src={require('../assets/speedrollr/3.png')} style={{borderRadius: 10}} alt='Speed Rollr Screenshot' />
                    </div>
                    <div>
                        <img src={require('../assets/speedrollr/2.png')} style={{borderRadius: 10}} alt='Speed Rollr Screenshot' />
                    </div>
                    <div>
                        <img src={require('../assets/speedrollr/1.png')} style={{borderRadius: 10}} alt='Speed Rollr Screenshot' />
                    </div>
                    </Carousel>
                </div>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>Roll into action and test your reflexes in Speed Rollr! Embark on a thrilling journey through a series of challenging stages where precision and timing are your best allies. Control your ball with simple swipe gestures as you navigate through dynamically changing environments, dodging obstacles and pitfalls along the way.</p>
                <div>
                    <p>Key Features:</p>
                    <ul>
                        <li>🎮 Intuitive Controls: Swipe to roll your ball left or right with ease.</li>
                        <br/>
                        <li>🏁 Challenging Stages: Navigate through a variety of increasingly difficult levels filled with obstacles and traps.</li>
                        <br/>
                        <li>🌀 Dynamic Environments: Encounter ever-changing landscapes and obstacles that keep you on your toes.</li>
                        <br/>
                        <li>🏆 Achievements and Leaderboards: Compete with friends and players worldwide to climb the leaderboards and earn bragging rights.</li>
                        <br/>
                        <li>🌟 Customization: Personalize your ball with unique skins and unlockable rewards.</li>
                        <br/>
                        <li>🎶 Immersive Sound Design: Immerse yourself in the action with an adrenaline-pumping soundtrack and immersive sound effects.</li>
                    </ul>
                </div>
                <p>Ready to roll? Download now and see if you have what it takes to conquer the challenges that await!</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/us/app/speed-rollr/id6479709612'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            {/* <Row style={{width: '100%', textAlign: 'center', marginTop: '1%'}}>
                <a href='https://play.google.com/store/apps/details?id=com.herocode.pixelpins'>
                    <Image
                    style={{width: '175px'}}
                        src={require('../assets/google-play-badge.png')}
                        alt=''
                    />
                </a>
            </Row> */}
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <Link to='/speedrollr/agreements'>Policies & Agreements</Link>
            </Row>
            <Row style={{marginTop: '3%'}}>
                <p style={{fontSize: '0.75em'}}>Speed Rollr uses Unreal® Engine. Unreal® is a trademark or registered trademark of Epic Games, Inc. in the United States of America and elsewhere. Unreal® Engine, Copyright 1998 – 2024, Epic Games, Inc. All rights reserved.</p>
            </Row>
        </Container>
    )
}

export default SpeedRollr