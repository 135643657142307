import React from 'react'
import { Container } from 'react-bootstrap'

const ContactUs = () => {
    return (
        <Container>
            <div>
                <h3 style={{marginBottom: '3%'}}>Contact Us</h3>                
                <p>Thank you for visiting our website! If you have any questions or comments about our services, please feel free to get in touch with us. We would love to hear from you!</p>
            </div>
            <div style={{marginTop: '5%'}}>
                <h3 style={{marginBottom: '2%'}}>Email</h3>
                <p>For general inquiries, please email us at <a href='mailto:support@herocode.us'>support@herocode.us</a>. We will respond to your message as soon as possible.</p>
            </div>
        </Container>
    )
}

export default ContactUs


// Phone
// You can reach us by phone at (555) 555-5555. Our support team is available Monday to Friday from 9am to 5pm.

// Email
// For general inquiries, please email us at info@appdevelopmentco.com. We will respond to your message as soon as possible.

// Live Chat
// If you prefer to chat with us directly, use the live chat feature on our website. Simply click on the chat icon in the bottom right corner of your screen, and one of our representatives will be happy to assist you.

// Social Media
// Follow us on social media for the latest updates and news. We're active on Facebook, Twitter, and Instagram.

// Facebook: @appdevelopmentco
// Twitter: @appdevelopmentco
// Instagram: @appdevelopmentco

// Mailing Address
// You can also reach us by mail at the following address:

// App Development Co.
// 123 Main Street
// Anytown, USA 12345