import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const BpTracker = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '450px',
                        borderRadius: 45,
                    }}
                    src={require('../assets/bp-tracker-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Track & Analyze Blood Pressure</p>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>Introducing Bp-Tracker - the app designed to help you easily monitor your blood pressure!</p>
                <p>Bp-Tracker is a powerful tool that allows you to track your blood pressure readings with ease. With advanced analytics and widgets, you can visualize your blood pressure trends over time, making it easy to identify changes and patterns. Whether you have high blood pressure, low blood pressure, or just want to keep tabs on your heart health, Bp-Tracker is the perfect solution.</p>
                <div>
                    <p>Key Features:</p>
                    <ul>
                        <li>Track your blood pressure readings with ease</li>
                        <br/>
                        <li>View your complete blood pressure history, filter your entries, and export or print your data for easy sharing with your doctor or healthcare provider</li>
                        <br/>
                        <li>Visualize your blood pressure trends over time with advanced analytics and customizable widgets</li>
                        <br/>
                        <li>Record the types of medication you take and track how your readings compare with each type of medication</li>
                    </ul>
                </div>
                <p>With Bp-Tracker, you can take control of your heart health and keep track of your blood pressure in a simple and effective way. The app is easy to use and provides you with all the tools you need to manage your hypertension or simply keep track of your heart health. Download Bp-Tracker today and start tracking your blood pressure like a pro!</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/app/bp-tracker/id1670204752'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '1%'}}>
                <a href='https://play.google.com/store/apps/details?id=com.herocode.bptracker'>
                    <Image
                    style={{width: '175px'}}
                        src={require('../assets/google-play-badge.png')}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <a href='https://www.herocode.us/bp-tracker/termsandconditions/'>Terms & Conditions</a>
                <a href='https://www.herocode.us/bp-tracker/privacypolicy/'>Privacy Policy</a>
            </Row>
        </Container>
    )
}

export default BpTracker