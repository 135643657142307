import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'

const PixelPins = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/pixelpins-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center', fontSize: 27}}>Let the Strikes Begin!</p>
            </Row>
            <Row style={{justifyContent: 'center', alignItems: 'center', marginTop: 25}}>
                <div style={{width: '90%'}}>
                    <Carousel>
                    <div>
                        <img src={require('../assets/pixelpins/1.png')} style={{borderRadius: 10}} alt='Pixel Pins Screenshot' />
                    </div>
                    <div>
                        <img src={require('../assets/pixelpins/2.png')} style={{borderRadius: 10}} alt='Pixel Pins Screenshot' />
                    </div>
                    <div>
                        <img src={require('../assets/pixelpins/3.png')} style={{borderRadius: 10}} alt='Pixel Pins Screenshot' />
                    </div>
                    <div>
                        <img src={require('../assets/pixelpins/4.png')} style={{borderRadius: 10}} alt='Pixel Pins Screenshot' />
                    </div>
                    </Carousel>
                </div>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>Welcome to Pixel Pins Bowling, the ultimate bowling experience right at your fingertips! Get ready to immerse yourself in the world of striking pins, stunning graphics, and addictive gameplay. Whether you're a seasoned pro looking for a challenge or a casual player seeking some fun, Pixel Pins Bowling has everything you need for hours of entertainment.</p>
                <div>
                    <p>Features:</p>
                    <ul>
                        <li>Realistic Physics: Enjoy lifelike physics and intuitive controls that mimic the experience of real-life bowling.</li>
                        <br/>
                        <li>Diverse Game Modes: Choose from Classic, Time Attack, and Trick Shot challenges to keep the fun rolling!</li>
                        <br/>
                        <li>Leaderboards: Check your ranking and compete for the top spot among global players!</li>
                        <br/>
                        <li>Customizations: Visit the Pro Shop to browse and select from a wide range of bowling balls, each offering unique features and advantages.</li>
                        <br/>
                        <li>Regular Updates: Stay tuned for exciting updates and new features as we continue to refine and enhance the bowling experience. With fresh content and improvements delivered regularly, the fun never ends!</li>
                    </ul>
                </div>
                <p>Get ready to lace up your bowling shoes and embark on an unforgettable bowling adventure with Pixel Pins Bowling. Download now and start rolling strikes today!</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/us/app/pixel-pins/id6478244962'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '1%'}}>
                <a href='https://play.google.com/store/apps/details?id=com.herocode.pixelpins'>
                    <Image
                    style={{width: '175px'}}
                        src={require('../assets/google-play-badge.png')}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <Link to='/pixelpins/agreements'>Policies & Agreements</Link>
            </Row>
            <Row style={{marginTop: '3%'}}>
                <p style={{fontSize: '0.75em'}}>Pixel Pins Bowling uses Unreal® Engine. Unreal® is a trademark or registered trademark of Epic Games, Inc. in the United States of America and elsewhere. Unreal® Engine, Copyright 1998 – 2024, Epic Games, Inc. All rights reserved.</p>
            </Row>
        </Container>
    )
}

export default PixelPins