import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const CardPixel = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/cardpixel-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '5%'}}>
                <p style={{textAlign: 'center'}}>Pixilate Contacts & Share Instantly! Connect Effortlessly with QR Codes.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>Introducing Card Pixel, the easiest way to share your contact details! Say goodbye to manually typing in your contact information or fumbling with business cards. With Card Pixel, you can generate a unique QR code from within the app that contains all your contact information, including name, phone number, email address, and more. Simply share the QR code with anyone you meet, and they can quickly and easily add your contact information to their phone. It's that simple!</p>
                <div>
                    <p>Key Features:</p>
                    <ul>
                        <li>Generate a unique QR code with all your contact information</li>
                        <br/>
                        <li>Share your QR code with anyone you meet</li>
                    </ul>
                </div>
                <p>With Card Pixel, you'll never miss out on potential connections again. Whether you're at a networking event, a job interview, or simply meeting someone new, Card Pixel makes it easy to share your contact details and stay connected.</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/app/card-pixel/id6445966352'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <a href='https://www.herocode.us/cardpixel/termsandconditions/'>Terms & Conditions</a>
                <a href='https://www.herocode.us/cardpixel/privacypolicy/'>Privacy Policy</a>
            </Row>
        </Container>
    )
}

export default CardPixel