import React from 'react'
import { Link } from 'react-router-dom'
import { Image, Navbar, Nav } from 'react-bootstrap'

const NavigationBar = () => {
    return (
        <Navbar
            collapseOnSelect
            expand='md'
            bg='dark'
            variant='dark'
            style={{marginBottom: '3%'}}
            >
            <Navbar.Brand>
                <Image
                    style={{
                        height: '30px',
                        marginLeft: '10%'
                    }}
                    src={require('../assets/herocode-logo.png')}
                />
            </Navbar.Brand>
            <Navbar.Toggle 
                aria-controls='responsive-navbar-nav' 
                style={{marginRight: '3%'}} 
                />
            <Navbar.Collapse 
                id='responsive-navbar-nav'
                >
                <Nav 
                    className='ms-auto' 
                    style={{marginRight: '3%'}}
                    >
                    <Nav.Link as={Link} to='/apps' eventKey={1}>Our Apps</Nav.Link>    
                    <Nav.Link as={Link} to='/contact' eventKey={2}>Contact Us</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavigationBar