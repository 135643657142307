import React from 'react'
import { Container, Col, Nav, Row, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const OurApps = () => {
    return (
        <Container>
            <h1 style={{marginBottom: '3%'}}>Our Apps</h1>
            <Row style={{marginTop: '10%', alignItems: 'baseline'}}>
            <Col 
                    md={4}
                    sm={12}
                    >
                    <Nav.Link as={Link} to='/obstacleoutrun' style={{marginTop: '5%'}}>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Image
                                style={{
                                    width: '200px',
                                    borderRadius: '22.5%',
                                }}
                                src={require('../assets/obstacleoutrun-icon.png')}
                            />
                            <h1 style={{textAlign: 'center', marginTop: 15}}>Obstacle Outrun</h1>
                        </Row>
                        <Row style={{width: '100%', marginTop: 10}}>
                            <p style={{textAlign: 'center'}}>Ultimate Race Challenge!</p>
                        </Row>
                    </Nav.Link>
                </Col>
                <Col 
                    md={4}
                    sm={12}
                    >
                <Nav.Link as={Link} to='/bp-tracker' style={{marginBottom: '5%'}}>
                    <Row style={{width: '100%', justifyContent: 'center'}}>
                        <Image
                            style={{
                                width: '200px',
                                borderRadius: '22.5%',
                            }}
                            src={require('../assets/bp-tracker-icon.png')}
                        />
                        <h1 style={{textAlign: 'center', marginTop: 10}}>Bp-Tracker</h1>
                    </Row>
                    <Row style={{width: '100%', marginTop: 10}}>
                        <p style={{textAlign: 'center'}}>Track & Analyze Blood Pressure</p>
                    </Row>
                </Nav.Link>
                </Col>
                <Col 
                    md={4}
                    sm={12}
                    >
                    <Nav.Link as={Link} to='/pixelgreens' style={{marginTop: '5%'}}>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Image
                                style={{
                                    width: '200px',
                                    borderRadius: '22.5%',
                                }}
                                src={require('../assets/pixelgreens-icon.png')}
                            />
                            <h1 style={{textAlign: 'center', marginTop: 15}}>Pixel Greens</h1>
                        </Row>
                        <Row style={{width: '100%', marginTop: 10}}>
                            <p style={{textAlign: 'center'}}>Mini Golf Like Never Before!</p>
                        </Row>
                    </Nav.Link>
                </Col>
                <Col 
                    md={4}
                    sm={12}
                    >
                    <Nav.Link as={Link} to='/sparkboost' style={{marginTop: '5%'}}>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Image
                                style={{
                                    width: '200px',
                                    borderRadius: '22.5%',
                                }}
                                src={require('../assets/sparkboost-icon.png')}
                            />
                            <h1 style={{textAlign: 'center', marginTop: 15}}>Spark Boost</h1>
                        </Row>
                        <Row style={{width: '100%', marginTop: 10}}>
                            <p style={{textAlign: 'center'}}>Motivational Quote App</p>
                        </Row>
                    </Nav.Link>
                </Col>
                <Col 
                    md={4}
                    sm={12}
                    >
                    <Nav.Link as={Link} to='/boxtup' style={{marginTop: '5%'}}>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Image
                                style={{
                                    width: '200px',
                                    borderRadius: '22.5%',
                                }}
                                src={require('../assets/boxtup-icon.png')}
                            />
                            <h1 style={{textAlign: 'center', marginTop: 15}}>BoxtUp</h1>
                        </Row>
                        <Row style={{width: '100%', marginTop: 10}}>
                            <p style={{textAlign: 'center'}}>Storage Solution with QR Codes</p>
                        </Row>
                    </Nav.Link>
                </Col>
                <Col 
                    md={4}
                    sm={12}
                    >
                    <Nav.Link as={Link} to='/studyflip' style={{marginTop: '5%'}}>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Image
                                style={{
                                    width: '200px',
                                    borderRadius: '22.5%',
                                }}
                                src={require('../assets/studyflip-icon.png')}
                            />
                            <h1 style={{textAlign: 'center', marginTop: 15}}>Study Flip</h1>
                        </Row>
                        <Row style={{width: '100%', marginTop: 10}}>
                            <p style={{textAlign: 'center'}}>Ultimate Flashcard Companion</p>
                        </Row>
                    </Nav.Link>
                </Col>
                <Col 
                    md={4}
                    sm={12}
                    >
                    <Nav.Link as={Link} to='/pixelscrollr' style={{marginTop: '5%'}}>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Image
                                style={{
                                    width: '200px',
                                    borderRadius: '22.5%',
                                }}
                                src={require('../assets/pixelscrollr-icon.png')}
                            />
                            <h1 style={{textAlign: 'center', marginTop: 15}}>Pixel Scrollr</h1>
                        </Row>
                        <Row style={{width: '100%', marginTop: 10}}>
                            <p style={{textAlign: 'center'}}>The Ultimate LED Marquee App</p>
                        </Row>
                    </Nav.Link>
                </Col>
                <Col 
                    md={4}
                    sm={12}
                    >
                    <Nav.Link as={Link} to='/cardpixel' style={{marginTop: '5%'}}>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Image
                                style={{
                                    width: '200px',
                                    borderRadius: '22.5%',
                                }}
                                src={require('../assets/cardpixel-icon.png')}
                            />
                            <h1 style={{textAlign: 'center', marginTop: 15}}>Card Pixel</h1>
                        </Row>
                        <Row style={{width: '100%', marginTop: 10}}>
                            <p style={{textAlign: 'center'}}>Pixilate Contacts & Share Instantly</p>
                        </Row>
                    </Nav.Link>
                </Col>
                <Col 
                    md={4}
                    sm={12}
                    >
                    <Nav.Link as={Link} to='/pixelpins' style={{marginTop: '5%'}}>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Image
                                style={{
                                    width: '200px',
                                    borderRadius: '22.5%',
                                }}
                                src={require('../assets/pixelpins-icon.png')}
                            />
                            <h1 style={{textAlign: 'center', marginTop: 15}}>Pixel Pins</h1>
                        </Row>
                        <Row style={{width: '100%', marginTop: 10}}>
                            <p style={{textAlign: 'center'}}>Let the Strikes Begin!</p>
                        </Row>
                    </Nav.Link>
                </Col>
                <Col 
                    md={4}
                    sm={12}
                    >
                    <Nav.Link as={Link} to='/speedrollr' style={{marginTop: '5%'}}>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Image
                                style={{
                                    width: '200px',
                                    borderRadius: '22.5%',
                                }}
                                src={require('../assets/speedrollr-icon.png')}
                            />
                            <h1 style={{textAlign: 'center', marginTop: 15}}>Speed Rollr</h1>
                        </Row>
                        <Row style={{width: '100%', marginTop: 10}}>
                            <p style={{textAlign: 'center'}}>Roll, Speed, Conquer!</p>
                        </Row>
                    </Nav.Link>
                </Col>
            </Row>
            <Col>
                <h5 style={{textAlign: 'center', marginTop: '10%'}}>More coming soon!</h5>
            </Col>
        </Container>
    )
}

export default OurApps