import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'

const PixelGreens = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/pixelgreens-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center', fontSize: 27}}>Mini Golf Like Never Before!</p>
            </Row>
            <Row style={{justifyContent: 'center', alignItems: 'center', marginTop: 25}}>
                <div style={{width: '90%'}}>
                    <Carousel>
                    <div>
                        <img src={require('../assets/pixelgreens/1.png')} style={{borderRadius: 10}} />
                    </div>
                    <div>
                        <img src={require('../assets/pixelgreens/2.png')} style={{borderRadius: 10}} />
                    </div>
                    <div>
                        <img src={require('../assets/pixelgreens/3.png')} style={{borderRadius: 10}} />
                    </div>
                    <div>
                        <img src={require('../assets/pixelgreens/4.png')} style={{borderRadius: 10}} />
                    </div>
                    </Carousel>
                </div>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>Pixel Greens Mini Golf, the most exciting and addictive miniature golf game! Get ready to tee off on a pixelated adventure that will challenge your putting skills, captivate your imagination, and provide hours of fun for players of all ages.</p>
                <p>Putt Your Way To Glory!</p>
                <p>Embark on the ultimate golfing adventure as you tackle enchanting courses, each brimming with a distinctive array of challenges and delightful surprises. Meticulously plan your shots, mastering the art of finding the precise angle and power needed to triumph on every hole.</p>
                <div>
                    <p>Features:</p>
                    <ul>
                        <li>Challenging Courses: Test your precision and strategy on a variety of inventive courses.</li>
                        <br/>
                        <li>Captivating Visuals and Imaginative Challenges - Experience the visual allure of a mesmerizing world filled with nostalgic charm, all while encountering an array of distinct and imaginative obstacles.</li>
                        <br/>
                        <li>Intuitive Controls: Easy-to-learn controls make this game accessible for players of all skill levels.</li>
                        <br/>
                        <li>Customizations: Personalize your golf ball with an array of unique skins and unlockable items. Make your ball truly your own as you aim for that perfect shot.</li>
                    </ul>
                </div>
                <p>Hours of Fun for Everyone!</p>
                <p>Whether you're a seasoned golfer or a first-time player, Pixel Greens Mini Golf offers an immersive and enjoyable experience for everyone. Perfect your putting skills, sink incredible shots, and enjoy the thrill of mastering every course</p>
                <p>Get ready to embark on a journey through beautifully designed, pixelated landscapes and challenge yourself to become a golfing legend! Download Pixel Greens Mini Golf today and start your quest for the perfect putt.</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/app/pixel-greens/id6470702610'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '1%'}}>
                <a href='https://play.google.com/store/apps/details?id=com.herocode.pixelgreens'>
                    <Image
                    style={{width: '175px'}}
                        src={require('../assets/google-play-badge.png')}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <Link to='/pixelgreens/agreements'>Policies & Agreements</Link>
            </Row>
            <Row style={{marginTop: '3%'}}>
                <p style={{fontSize: '0.75em'}}>Pixel Greens Mini Golf uses Unreal® Engine. Unreal® is a trademark or registered trademark of Epic Games, Inc. in the United States of America and elsewhere. Unreal® Engine, Copyright 1998 – 2024, Epic Games, Inc. All rights reserved.</p>
            </Row>
        </Container>
    )
}

export default PixelGreens