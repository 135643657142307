import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'

const ObstacleOutrun = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/obstacleoutrun-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center', fontSize: 27}}>Ultimate Race Challenge!</p>
            </Row>
            <Row style={{justifyContent: 'center', alignItems: 'center', marginTop: 25}}>
                <div style={{width: '90%'}}>
                    <Carousel>
                        <div>
                            <img src={require('../assets/obstacleoutrun/1.png')} style={{borderRadius: 10}} />
                        </div>
                        <div>
                            <img src={require('../assets/obstacleoutrun/2.png')} style={{borderRadius: 10}} />
                        </div>
                        <div>
                            <img src={require('../assets/obstacleoutrun/3.png')} style={{borderRadius: 10}} />
                        </div>
                    </Carousel>
                </div>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>Gear up for an adrenaline-fueled multiplayer experience like no other! Take on players from around the globe in a heart-pounding race through treacherous obstacle courses where only the fastest will prevail.</p>
                <div>
                    <p>Key Features:</p>
                    <ul>
                        <li>🏁 Epic Multiplayer Races: Enter the arena and go head-to-head with other players in adrenaline-fueled races to be the first to claim the flag at the end of the course.</li>
                        <br/>
                        <li>🚧 Treacherous Obstacle Courses: Navigate through a variety of perilous obstacles, including collapsing floors, moving platforms, and obstacles that threaten to knock you off course.</li>
                        <br/>
                        <li>🔄 Strategic Checkpoints: Plan your route wisely and utilize the checkpoint system to your advantage, ensuring you can respawn and continue the race if you meet an unfortunate demise.</li>
                        <br/>
                        <li>🥇 Race for Victory: Utilize your reflexes and cunning to outmaneuver opponents and be the first to reach the flag in intense races where only the swiftest will triumph.</li>
                        <br/>
                        <li>🎨 Customizable Avatars: Stand out from the competition by personalizing your racer with a selection of unique skins and unlockable rewards.</li>
                    </ul>
                </div>
                <p>Hours of Fun for Everyone!</p>
                <p>Are you ready to conquer the ultimate obstacle course challenge and claim your place at the top? Download Obstacle Outrun now and prove your skills in the race of a lifetime!</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/us/app/obstacle-outrun/id6496854016'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '1%'}}>
                <a href='https://play.google.com/store/apps/details?id=com.herocode.obstacleoutrun'>
                    <Image
                    style={{width: '175px'}}
                        src={require('../assets/google-play-badge.png')}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <Link to='/obstacleoutrun/agreements'>Policies & Agreements</Link>
            </Row>
            <Row style={{marginTop: '3%'}}>
                <p style={{fontSize: '0.75em'}}>Obstacle Outrun uses Unreal® Engine. Unreal® is a trademark or registered trademark of Epic Games, Inc. in the United States of America and elsewhere. Unreal® Engine, Copyright 1998 – 2024, Epic Games, Inc. All rights reserved.</p>
            </Row>
        </Container>
    )
}

export default ObstacleOutrun