import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const PixelScrollr = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/pixelscrollr-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '5%'}}>
                <p style={{textAlign: 'center'}}>The Ultimate LED Marquee App!</p>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>Turn your text into eye-catching LED banner marquee displays with Pixel Scrollr! This innovative app allows you to create personalized scrolling messages on your device, resembling classic LED banners seen in stadiums, concerts, and city centers. With a range of customizable features, you can unleash your creativity and share your messages in a unique and attention-grabbing way.</p>
                <div>
                    <p>Key Features:</p>
                    <ul>
                        <li>Create Stunning LED Banners: Enter any text you like, and watch it come to life as a mesmerizing LED banner that scrolls across your screen.</li>
                        <br/>
                        <li>Customizable Fonts: Choose from a wide selection of stylish fonts to give your messages the perfect look and feel.</li>
                        <br/>
                        <li>Special Effects Backgrounds: Infuse your messages with shimmering glitters, vibrant illuminations, and electrifying flashes for an eye-catching and dynamic display!</li>
                        <br/>
                        <li>Vibrant Colors: Personalize the background and foreground colors to create striking visual effects that match your style or the mood of your message.</li>
                        <br/>
                        <li>Vibrate on Completion: Enable the vibrate option to receive tactile feedback when your LED banner message finishes scrolling.</li>
                        <br/>
                        <li>Mirroring: The mirror effect reflects the text as if it were being viewed in a mirror, adding an intriguing and visually engaging element to your marquee designs.</li>
                        <br/>
                        <li>Preview and Adjust: Get a real-time preview of your LED banner before finalizing, allowing you to make any necessary adjustments for the perfect result.</li>
                    </ul>
                </div>
                <p>Get Pixel Scrollr now and let your words shine like never before! Whether you want to send a special message to someone, make announcements at events, or simply have fun with creative texts, Pixel Scrollr brings the captivating charm of LED banners right to your fingertips.</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/app/pixel-scrollr/id6455428953'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '1%'}}>
                <a href='https://play.google.com/store/apps/details?id=com.herocode.pixelscrollr'>
                    <Image
                    style={{width: '175px'}}
                        src={require('../assets/google-play-badge.png')}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <a href='https://www.herocode.us/pixelscrollr/termsandconditions/'>Terms & Conditions</a>
                <a href='https://www.herocode.us/pixelscrollr/privacypolicy/'>Privacy Policy</a>
            </Row>
        </Container>
    )
}

export default PixelScrollr