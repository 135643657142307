import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const WifiConnector = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/wifi-connector-logo.png')}
                />
            </Row>
            <Row style={{width: '100%', marginTop: '5%'}}>
                <p style={{textAlign: 'center'}}>WiFi Simplified with QR Codes</p>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p>WiFi-Connector is the ultimate app for sharing and connecting to wireless networks seamlessly. Say goodbye to the hassle of manually entering complex passwords or struggling to remember them. With WiFi-Connector, you can securely store and share your WiFi details with just a few taps, making it effortless for you and your friends to connect.</p>
                <div>
                    <p>Key Features:</p>
                    <ul>
                        <li>Simplified WiFi Sharing: Generate QR codes containing your WiFi credentials, allowing anyone to quickly connect to your network by scanning the code.</li>
                        <br/>
                        <li>Print and Export: Easily print or export your WiFi details, making it a breeze to share with guests, colleagues, or anyone who needs temporary access.</li>
                        <br/>
                        <li>Convenient Password Management: Store all your WiFi passwords securely in one place, eliminating the need to remember multiple complex passwords.</li>
                        <br/>
                        <li>Intuitive User Interface: Enjoy a user-friendly design with a streamlined interface that makes sharing WiFi credentials a breeze.</li>
                    </ul>
                </div>
                <p>Download WiFi-Connector today and experience the seamless way to share and connect to WiFi networks!</p>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '3%'}}>
                <a href='https://apps.apple.com/app/wifi-connector/id6449268362'>
                    <Image
                    style={{width: '150px'}}
                        src={require('../assets/apple-store-badge.svg').default}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', textAlign: 'center', marginTop: '1%'}}>
                <a href='https://play.google.com/store/apps/details?id=com.herocode.wificonnector'>
                    <Image
                    style={{width: '175px'}}
                        src={require('../assets/google-play-badge.png')}
                        alt=''
                    />
                </a>
            </Row>
            <Row style={{width: '100%', marginTop: '3%'}}>
                <p style={{textAlign: 'center'}}>Please be sure to check out our contact page if you have any questions.</p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row', textAlign: 'center', display: 'inline-block'}}>
                <a href='https://www.herocode.us/wifi-connector/termsandconditions/'>Terms & Conditions</a>
                <a href='https://www.herocode.us/wifi-connector/privacypolicy/'>Privacy Policy</a>
            </Row>
        </Container>
    )
}

export default WifiConnector