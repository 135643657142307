import React from 'react'
import ApplicationRouter from './router/ApplicationRouter'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

const App = () => {
	return (
		<ApplicationRouter />
	)
}

export default App